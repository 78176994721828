import { Label } from "@/src/components/ui/label";
import { Station } from "@/src/types/schedule";

const ProviderSelectionCard: React.FC<{
	station: Station;
	onClick: () => void;
}> = ({ station, onClick }) => {
	return (
		<li
			className="flex cursor-pointer flex-col space-y-1 rounded-xl bg-[#F1F3FC] p-2"
			onClick={onClick}
		>
			<div className="flex gap-x-2">
				<input
					type="radio"
					className="accent-primary"
					name="station_choice"
				/>
				<div>
					<div className="flex items-center justify-between space-x-1">
						<Label className="text-sm font-medium text-[#09090B]">
							{station.name}
						</Label>
					</div>
					<p className="hidden text-xs text-main-7">
						Lorem ipsum dolor sit amet consectetur. Nunc diam ipsum
						faucibus mi nibh turpis elementum faucibus.
					</p>
				</div>
			</div>
		</li>
	);
};

export default ProviderSelectionCard;
