import { useEffect } from "react";
import welcomeImage from "@/public/assets/appointment-icon.svg";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../components/Waitlist/CustomInput";
import { Button } from "@/src/components/ui/button";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	useCreateAppointment,
	useVerifyCustomer,
} from "@/src/store/slices/scheduleSlice";
import useScheduleStore from "@/src/store/useScheduleStore";
import Loader from "@/src/components/Loader/Loader";
import useCustomToast from "@/src/components/CustomToast";

const ValidationForm = () => {
	const navigate = useNavigate();
	const { scheduling_code } = useParams();
	const toast = useCustomToast();
	const {
		appointmentDetails,
		bookingType,
		setAppointmentDetails,
		setValidationCode,
		validationDetails,
		step,
		setStep,
	} = useScheduleStore();

	const schema = z.object(
		validationDetails.reduce((acc, validator) => {
			acc[validator.key] = z
				.string()
				.min(1, { message: `${validator.name} is required` });
			return acc;
		}, {})
	);

	type FormFields = z.infer<typeof schema>;

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<FormFields>({
		mode: "onChange",
		resolver: zodResolver(schema),
	});

	useEffect(() => {
		if (!validationDetails?.length) {
			setStep(1);
		}
	}, [appointmentDetails, navigate, setStep, validationDetails]);

	const onSubmit = (data: FormFields) => {
		mutateVerifyCustomer({
			schedule_code: scheduling_code as string,
			data: data,
		});
	};

	const { mutate: mutateVerifyCustomer, isPending } = useVerifyCustomer({
		onSuccess: (data) => {
			setAppointmentDetails(data?.data);
			setValidationCode(
				(
					data as unknown as {
						code_type: string;
						customer_verification_token: string;
						message: string;
						status: string;
					}
				)?.customer_verification_token
			);
			if (bookingType === "station") {
				setStep(2);
			} else {
				setStep(1);
			}
		},
		onError: (error) => {
			const errorMessage =
				(error?.response?.data as { message: string })?.message ||
				"User is not authorized";

			toast(errorMessage, {
				type: "error",
				id: "validation-error",
				duration: 3000,
			});
		},
	});

	return (
		<div className="flex flex-col gap-y-4">
			<div className=" flex w-full items-center justify-between gap-x-4  bg-white px-6 font-hoves lg:px-12">
				<button
					className="w-full flex-[0.2]"
					onClick={() => navigate(-1)}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
							stroke="#858C95"
							strokeWidth="2"
							strokeLinecap="round"
						/>
					</svg>
				</button>

				<p className="w-full border-b-2 border-b-primary py-6 text-center font-semibold text-primary">
					Validation
				</p>
				<button
					className="flex w-full flex-[0.2] items-center justify-end"
					onClick={() => navigate(-1)}
					disabled={step == 1}
				>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 6L6 18M18 18L6 6.00001"
							stroke="#858C95"
							strokeWidth="2"
							strokeLinecap="round"
						/>
					</svg>
				</button>
			</div>
			<div className="h-full">
				<div className="bg-primary-3 h-auto overflow-hidden py-8 font-hoves md:h-screen md:py-0">
					<div className="h-full flex-col items-center justify-between gap-y-4 px-4 md:flex md:gap-x-12 lg:flex-row lg:gap-x-24 lg:px-24">
						<div className=" hidden w-full justify-center md:flex md:h-full">
							<img
								className=" hidden w-[30%] md:block lg:w-[75%]"
								src={welcomeImage}
								alt=""
							/>
						</div>
						<div className="flex w-full justify-center">
							<div className=" h-auto rounded-[12px] bg-white px-6 pb-4 pt-10 text-main-4 sm:w-[441px] md:w-full md:max-w-[80%] lg:max-w-full lg:py-4">
								<p className="mt-7 text-[22px] text-lg font-semibold -tracking-1% text-main-1 md:mt-3 lg:leading-[30px]">
									Validate Your Information
								</p>
								<form onSubmit={handleSubmit(onSubmit)}>
									{validationDetails?.map((validator) => (
										<div
											className="mt-4"
											key={validator?.id}
										>
											<CustomInput
												label={validator?.name}
												error={
													(errors[validator?.key]
														?.message ||
														"") as string
												}
												register={{
													...register(validator?.key),
												}}
												required
												input
											/>
										</div>
									))}
									<p className="ld:text-base mb-8 mt-12 font-inter text-sm leading-[22.4px] tracking-0.5% text-main-4 md:mt-8">
										By confirming, you accept our{" "}
										<a
											className="border-b border-main-1 text-main-1"
											href=""
										>
											Terms
										</a>{" "}
										and{" "}
										<a
											className="border-b border-main-1 text-main-1"
											href=""
										>
											Policies
										</a>
										.
									</p>
									<div>
										<Button
											className=" flex w-full bg-primary text-white"
											type="submit"
											disabled={isPending}
										>
											{isPending ? (
												<Loader size={16} />
											) : (
												"Continue"
											)}
										</Button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ValidationForm;
