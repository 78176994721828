/* eslint-disable @typescript-eslint/no-explicit-any */
import { create } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import {
	ScheduleInfoData,
	ScheduleLocationData,
	ScheduleStationData,
} from "../types/schedule";

interface ScheduleState {
	submissionDetails: Record<string, any> | any;
	bookingType: "station" | "location" | "business" | null;
	appointmentChoice: "service" | "provider" | null;
	appointmentStatusDetails: Record<string, any> | null;
	scheduleCode: string;
	selectedStation: Record<string, any> | any;
	selectedService: Record<string, any> | any;
	selectedStationDetails: string;
	appointmentDetails: ScheduleInfoData | null;
	appointmentCustomFields: any[];
	appointmentStationDetails: ScheduleStationData | null;
	appointmentLocationDetails: ScheduleLocationData | null;
	step: number;
	validationDetails: any[];
	validationCode: string;
	temporarySlotToken: string | undefined;
	customerToken: string | undefined;

	//Setter functions
	setStep: (step: number) => void;
	setSubmissionDetails: (submissionDetails: Record<string, any>) => void;
	setBookingType: (type: "station" | "location" | "business" | null) => void;
	setAppointmentChoice: (choice: "service" | "provider") => void;
	setAppointmentStatusDetails: (
		appointmentStatusDetails: Record<string, any>
	) => void;
	setScheduleCode: (code: string) => void;
	setSelectedStation: (station: any[]) => void;
	setSelectedService: (service: any[]) => void;
	setSelectedStationDetails: (details: string) => void;
	setAppointmentDetails: (details: ScheduleInfoData) => void;
	setAppointmentCustomFields: (fields: any[]) => void;
	setAppointmentStationDetails: (details: ScheduleStationData) => void;
	setAppointmentLocationDetails: (details: ScheduleLocationData) => void;
	setValidationDetails: (details: any[] | undefined) => void;
	setValidationCode: (code: string) => void;
	setTemporarySlotToken: (token: string | undefined) => void;
	setCustomerToken: (token: string) => void;
	reset: () => void;
}

const initialState = {
	submissionDetails: {},
	bookingType: null,
	appointmentChoice: null,
	appointmentStatusDetails: null,
	scheduleCode: "",
	selectedStation: {},
	selectedService: {},
	selectedStationDetails: "",
	appointmentDetails: null,
	appointmentCustomFields: [],
	appointmentStationDetails: null,
	appointmentLocationDetails: null,
	step: 0,
	validationCode: "",
	validationDetails: [],
	temporarySlotToken: undefined,
	customerToken: undefined,
};

type SchedulePersist = PersistOptions<ScheduleState>;

const useScheduleStore = create<ScheduleState>()(
	persist(
		(set) => ({
			...initialState,
			setSubmissionDetails: (details) =>
				set({ submissionDetails: details }),
			setAppointmentStatusDetails: (details) =>
				set({ appointmentStatusDetails: details }),
			setBookingType: (type) => set({ bookingType: type }),
			setAppointmentChoice: (choice) =>
				set({ appointmentChoice: choice }),
			setScheduleCode: (code) => set({ scheduleCode: code }),
			setSelectedStation: (station) => set({ selectedStation: station }),
			setSelectedService: (service) => set({ selectedService: service }),
			setSelectedStationDetails: (details) =>
				set({ selectedStationDetails: details }),
			setAppointmentDetails: (details) =>
				set({ appointmentDetails: details }),
			setAppointmentCustomFields: (fields) =>
				set({ appointmentCustomFields: fields }),
			setAppointmentStationDetails: (details) =>
				set({ appointmentStationDetails: details }),
			setAppointmentLocationDetails: (details) =>
				set({ appointmentLocationDetails: details }),
			setStep: (step) => set({ step }),
			setValidationDetails: (details: any[] | undefined) =>
				set({
					validationDetails: details,
				}),
			setValidationCode: (code: string) =>
				set({
					validationCode: code,
				}),
			setTemporarySlotToken: (token: string | undefined) =>
				set({
					temporarySlotToken: token,
				}),
			setCustomerToken: (token: string) =>
				set({
					customerToken: token,
				}),
			reset: () =>
				set((state) => ({
					...initialState,
					submissionDetails: state.submissionDetails,
				})),
		}),
		{
			name: "schedule-store",
			getStorage: () => localStorage,
		} as SchedulePersist
	)
);

export default useScheduleStore;
