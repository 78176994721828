import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/src/components/ui/dialog";
import { useNavigate } from "react-router";
import useCustomToast from "@/src/components/CustomToast";
import { Button } from "@/src/components/ui/button";
import { LoaderButton } from "@/src/components/ui-extended/loader-button";
import { useCancelReservedSlot } from "@/src/store/slices/scheduleSlice";
import { DialogDescription } from "@radix-ui/react-dialog";
import useScheduleStore from "@/src/store/useScheduleStore";

const CancelTimeslotReservationModal = ({ isOpen, setIsOpen }) => {
	const toast = useCustomToast();
	const navigate = useNavigate();
	const slotToken = useScheduleStore((s) => s.temporarySlotToken);
	const scheduleCode = useScheduleStore((s) => s.scheduleCode);
	const cancelAppointment = () => {
		mutateCancelReservedSlot({ slot_token: slotToken as string });
	};

	const { mutate: mutateCancelReservedSlot, isPending } =
		useCancelReservedSlot({
			onSuccess: (data) => {
				const message =
					(data as unknown as { message: string })?.message ||
					"Reservation cancelled successfully";
				toast(message, {
					id: "cancel-reserved-timeslot-success",
					type: "success",
					duration: 2500,
				});
				navigate(`/schedule/${scheduleCode}`);
			},
			onError: () => {
				toast("An error occured cancelling this reservation", {
					id: "cancel-reserved-timeslot",
					type: "error",
					duration: 2500,
				});
				navigate(`/schedule/${scheduleCode}`);
			},
		});

	return (
		<Dialog
			open={isOpen}
			onOpenChange={setIsOpen}
			aria-describedby="appointment-cancellation-modal"
		>
			<DialogContent className="flex w-full max-w-[360px] rounded-[10px] px-3 py-4">
				<div className="flex gap-2">
					<i className="mgc_information_line mt-1.5 text-[20px] before:!text-main-4" />
					<div className="flex-1">
						<div className="flex flex-col gap-y-2">
							<DialogHeader>
								<DialogTitle className="flex items-center justify-between">
									<span className="text-xl font-semibold leading-[30px] tracking-[-0.22px]">
										Cancel Appointment
									</span>
								</DialogTitle>
								<DialogDescription className="sr-only">
									Cancel your appointment
								</DialogDescription>
							</DialogHeader>

							<p className="text-sm tracking-[-0.1px] text-main-7">
								Are you sure you want to cancel this reservation
								? After cancelling appointment, all your
								information will be removed.{" "}
							</p>

							<p className="pt-4 font-semibold">
								This action can&apos;t be undone.
							</p>
						</div>
						<DialogFooter className="mt-6 flex w-full !flex-row space-x-2.5">
							<Button
								className="flex-1"
								variant="outline"
								onClick={() => {
									if (!isPending) {
										setIsOpen(false);
									}
								}}
								disabled={isPending}
							>
								Undo
							</Button>
							<LoaderButton
								className="flex-1 text-white"
								type="submit"
								variant="destructive"
								loaderSize={20}
								onClick={cancelAppointment}
								loading={isPending}
								disabled={isPending}
							>
								Cancel Appointment
							</LoaderButton>
						</DialogFooter>
					</div>
					<DialogClose className="flex items-start">
						<i className="mgc_close_line mt-1.5 text-lg before:!text-main-4" />
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default CancelTimeslotReservationModal;
