import useCustomToast from "@/src/components/CustomToast";
import Loader from "@/src/components/Loader/Loader";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import { Button } from "@/src/components/ui/button";
import CustomIntakeField from "@/src/components/Waitlist/CustomIntakeField";
import {
	useCompleteReservation,
	useCreateAppointment,
	useGetSlotTokenInformation,
} from "@/src/store/slices/scheduleSlice";
import useScheduleStore from "@/src/store/useScheduleStore";
import { CustomIntake } from "@/src/types/custom-intakes";
import { createScheduleCustomFieldsSchema } from "@/src/types/schedule";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { AxiosResponse, isAxiosError } from "axios";
import CountdownTimer from "./components/CountdownTimer";
import { useErrorNotification } from "@/src/hooks/useErrorNotification";
import CancelTimeslotReservationModal from "./components/CancelTimeslotReservationModal";

const IntakeForm = () => {
	const { scheduling_code } = useParams();
	const { formData, setFormData } = useFormContext();
	const toast = useCustomToast();
	const [submissionType, setSubmissionType] = useState<
		"save" | "skip" | null
	>(null);
	const [showCancelReservationModal, setShowCancelReservationModal] =
		useState(false);
	const navigate = useNavigate();

	const {
		bookingType,
		selectedService,
		selectedStation,
		appointmentChoice,
		setSubmissionDetails,
		setStep,
		validationCode,
		validationDetails,
		temporarySlotToken,
		scheduleCode,
		reset,
	} = useScheduleStore();

	const booking_code =
		bookingType == "station"
			? scheduling_code || selectedStation?.schedule_code
			: selectedStation?.schedule_code ||
				selectedService?.schedule_code ||
				scheduleCode;
	//get slot information by passing slot token gotten
	// const {
	// 	data: customFieldData,
	// 	isSuccess,
	// 	isLoading,
	// } = useGetScheduleCodeCustomFields(booking_code!);

	const {
		data: tokenInformation,
		error: tokenRetrievalError,
		isSuccess: isTokenRetrievalSuccess,
		isError: isTokenRetrievalError,
		isLoading: isTokenInformationLoading,
	} = useGetSlotTokenInformation(temporarySlotToken!);

	const errorMessage = isAxiosError(tokenRetrievalError)
		? tokenRetrievalError.response?.data?.message ||
			tokenRetrievalError.message
		: "An unknown error occurred";

	useErrorNotification({
		isError: isTokenRetrievalError,
		message: errorMessage,
		id: "token-error",
		duration: 3000,
		action: () => {
			navigate(-1);
		},
	});

	const [customFieldsSchema, setCustomFieldsSchema] = useState(
		createScheduleCustomFieldsSchema([])
	);
	const [checkedTermsConditions, setCheckedTermsConditions] =
		useState<boolean>(false);

	useEffect(() => {
		if (tokenInformation?.data === null) {
			return;
		}
		if (isTokenRetrievalSuccess && tokenInformation?.data?.custom_intakes) {
			const newSchema = createScheduleCustomFieldsSchema(
				tokenInformation?.data?.custom_intakes
			);
			setCustomFieldsSchema(newSchema);
		}
	}, [
		scheduling_code,
		navigate,
		isTokenRetrievalSuccess,
		tokenInformation?.data,
	]);

	const {
		register,
		handleSubmit,
		control,
		watch,
		formState: { errors, isValid },
	} = useForm<z.infer<typeof customFieldsSchema>>({
		resolver: zodResolver(customFieldsSchema),
		mode: "onChange",
		shouldUnregister: true,
		reValidateMode: "onChange",
		defaultValues: {
			custom_intakes: {},
		},
	});

	// Check if there are any required fields with errors
	function hasRequiredFields() {
		return tokenInformation?.data?.custom_intakes?.some(
			(field) => field.field_requirement === "yes"
		);
	}

	const customIntakesFormat = (data: z.infer<typeof customFieldsSchema>) => {
		if (!data?.custom_intakes) return undefined;
		const fullCustomIntakes: Record<string, any> = {};
		tokenInformation?.data?.custom_intakes?.forEach((intake) => {
			if (!data?.custom_intakes) return undefined;
			const value = data?.custom_intakes[intake.key];

			if (value === "" || value === undefined) return;
			if (intake.type === "infoText") {
				return;
			}
			switch (intake.type) {
				case "text":
					if (typeof value === "string" && value.trim() !== "") {
						fullCustomIntakes[intake.key] = value.trim();
					}
					break;
				case "numeric":
					fullCustomIntakes[intake.key] = value;
					break;

				case "boolean":
					fullCustomIntakes[intake.key] = value;
					break;

				case "date":
					fullCustomIntakes[intake.key] =
						value && value instanceof Date
							? value.toISOString().split("T")[0]
							: value;
					break;

				case "date_range":
					if (
						value &&
						typeof value === "object" &&
						"from" in value &&
						"to" in value
					) {
						fullCustomIntakes[intake.key] = `${
							value.from instanceof Date
								? value.from.toISOString().split("T")[0]
								: value.from
						} - ${
							value.to instanceof Date
								? value.to.toISOString().split("T")[0]
								: value.to
						}`;
					}
					break;

				case "dropdown":
					fullCustomIntakes[intake.key] = Array.isArray(value)
						? value.map((v) => v.value)
						: undefined;
					break;
				case "checkbox":
					fullCustomIntakes[intake.key] = value;
					break;

				case "attachment":
					// Handle file attachment (you might need to implement file upload logic)
					fullCustomIntakes[intake.key] =
						value instanceof File ? value.name : value;
					break;

				default:
					fullCustomIntakes[intake.key] = value;
			}
		});

		return fullCustomIntakes;
	};

	const {
		mutate: completeReservation,
		isPending: isCompleteReservationPending,
	} = useCompleteReservation({
		onSuccess: () => {
			return;
		},
	});

	const onSubmit: SubmitHandler<z.infer<typeof customFieldsSchema>> = (
		data
	) => {
		if (!checkedTermsConditions) {
			toast(
				"Please agree to the HIPAA terms and conditions before proceeding.",
				{
					type: "error",
					id: "hipaa-error",
				}
			);
			setSubmissionType(null);
			return;
		}
		if (!scheduling_code) {
			toast("Appointment code is missing.", {
				type: "error",
				id: "missing-appointment-code",
			});
			setSubmissionType(null);
			return;
		}
		if (!validationDetails?.length && !validationCode) {
			if (Object.keys(formData).length < 2) {
				return navigate(`/schedule/${scheduling_code}`);
			}
		}

		const formattedCustomIntakes = customIntakesFormat(data);
		setFormData((prevData) => ({
			...prevData,
			custom_intakes: formattedCustomIntakes,
		}));

		completeReservation(
			{
				slot_token: temporarySlotToken as string,
				data: {
					...(validationDetails?.length
						? {
								customer_verification_token: validationCode,
							}
						: {
								full_name: formData?.patientDetails?.full_name,
								phone_number:
									formData?.patientDetails?.phone_number,
								email: formData?.patientDetails?.email,
							}),

					custom_intakes: formattedCustomIntakes,
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					reset();
					setSubmissionDetails(data.data);
					toast("Customer scheduled successfully", {
						type: "success",
						id: "schedule-submission-success",
					});
					setStep(4);
					setSubmissionType(null);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission-error",
					});
					setSubmissionType(null);
				},
			}
		);
	};

	const submitWithoutCustomFields = () => {
		if (!checkedTermsConditions) {
			toast(
				"Please agree to the HIPAA terms and conditions before proceeding.",
				{
					type: "error",
					id: "hipaa-error",
				}
			);
			setSubmissionType(null);
			return;
		}
		if (!scheduling_code) {
			toast("Appointment code is missing.", {
				type: "error",
				id: "missing-appointment-code",
			});
			setSubmissionType(null);
			return;
		}
		if (Object.keys(formData).length < 2) {
			return navigate(`/schedule/${scheduling_code}`);
		}
		completeReservation(
			{
				slot_token: temporarySlotToken as string,
				data: {
					...(validationDetails?.length
						? {
								customer_verification_token: validationCode,
							}
						: {
								full_name: formData?.patientDetails?.full_name,
								phone_number:
									formData?.patientDetails?.phone_number,
								email: formData?.patientDetails?.email,
							}),
				},
			},
			{
				onSuccess: (data: AxiosResponse) => {
					reset();
					setSubmissionDetails(data.data);
					toast("Customer scheduled successfully", {
						type: "success",
						id: "schedule-submission-success",
					});
					setStep(4);
					setSubmissionType(null);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message || "An error occured";
					toast(errorMessage, {
						type: "error",
						id: "schedule-submission-error",
					});
					setSubmissionType(null);
				},
			}
		);
	};
	const customIntakesValues = watch("custom_intakes");

	const areAllFieldsFilled = () => {
		if (!tokenInformation?.data?.custom_intakes) {
			return false;
		}

		const fields = tokenInformation?.data?.custom_intakes;

		const hasOnlyInfoTextFields = fields.every(
			(field) => field.type === "infoText"
		);

		if (hasOnlyInfoTextFields) {
			return true;
		}

		if (!customIntakesValues) {
			return false;
		}
		return fields?.every((field) => {
			const value = customIntakesValues && customIntakesValues[field.key];
			if (field.type === "infoText") {
				return true; // No need to validate or submit infoText
			}
			switch (field.type) {
				case "text":
				case "numeric":
					return value !== undefined && value !== "";
				case "dropdown":
					return Array.isArray(value) && value.length > 0;
				case "date":
					return value !== null && value !== undefined;
				case "date_range":
					return value?.from && value?.to;
				case "boolean":
				case "infoText":
					return true;
				case "checkbox":
					return typeof value === "boolean";
				default:
					return (
						value !== null && value !== undefined && value !== ""
					);
			}
		});
	};

	return (
		<>
			<div className="flex min-h-[100svh] flex-col bg-[#E5E5E7]">
				<div className="flex w-full items-center border-b-2 border-b-primary bg-white font-hoves ">
					<h1 className="mx-auto w-full py-4 text-center font-semibold text-primary ">
						Intake Form
					</h1>
					<button
						className="ml-auto w-fit pr-4"
						onClick={() => navigate(-1)}
					>
						<IoClose className="text-lg" />
					</button>
				</div>

				<div className="mx-auto flex w-full flex-col  items-center justify-between gap-x-4 gap-y-3 px-4 py-4 sm:flex-row md:max-w-[80%]">
					<CountdownTimer />
					<Button
						className="bg-red-500 hover:bg-red-600"
						type="button"
						onClick={() => setShowCancelReservationModal(true)}
					>
						Cancel Reservation
					</Button>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mx-auto my-4 flex w-full max-w-[95%] flex-col gap-y-3 rounded-md bg-white p-4 md:max-w-[80%]"
				>
					{tokenInformation?.data?.custom_intakes?.length ? (
						<>
							<div className="flex flex-col gap-y-2">
								<h1 className="font-semibold text-main-1">
									Now, For Some More Details
								</h1>
								<p className="max-w-[294px] text-[#6D748D]">
									Only your first name will be shown on the
									waitlist community
								</p>
							</div>
							<div className="flex flex-col gap-y-4">
								{tokenInformation?.data?.custom_intakes?.map(
									(custom, idx) => (
										<CustomIntakeField
											key={idx}
											intake={custom as CustomIntake}
											register={register}
											errors={errors}
											control={control}
										/>
									)
								)}
							</div>
							<div className="flex flex-col gap-y-2 pt-4">
								<div className="flex items-center gap-2 font-light ">
									<input
										type="checkbox"
										className="accent-primary"
										checked={checkedTermsConditions}
										onChange={() => {
											setCheckedTermsConditions(
												!checkedTermsConditions
											);
										}}
									/>
									<p className="text-sm text-[#757575]">
										[HIPPA TERMS AND CONDITIONS ]
									</p>
								</div>
								<p className=" text-sm ">
									By confirming, you accept your{" "}
									<span className="underline underline-offset-1">
										Terms
									</span>{" "}
									and{" "}
									<span className="underline underline-offset-1">
										Policies
									</span>
								</p>
							</div>

							<Button
								className="bg-primary"
								type="submit"
								disabled={
									isCompleteReservationPending ||
									!isValid ||
									!areAllFieldsFilled()
								}
								onClick={() => {
									setSubmissionType("save");
									handleSubmit(onSubmit)();
								}}
							>
								{submissionType === "save" &&
								isCompleteReservationPending ? (
									<Loader size={18} />
								) : (
									"Save"
								)}
							</Button>
							<Button
								type="button"
								variant={"outline"}
								disabled={hasRequiredFields()}
								onClick={() => {
									setSubmissionType("skip");
									submitWithoutCustomFields();
								}}
							>
								{submissionType === "skip" &&
								isCompleteReservationPending ? (
									<Loader size={18} />
								) : (
									"Skip"
								)}
							</Button>
						</>
					) : (
						<>
							<p className="pb-4 text-center  text-lg  text-primary">
								No custom fields available? Please proceed to
								schedule{" "}
							</p>

							<div className="flex flex-col gap-y-2 pt-8">
								<div className="flex items-center gap-2 font-light ">
									<input
										type="checkbox"
										className="accent-primary"
										checked={checkedTermsConditions}
										onChange={() => {
											setCheckedTermsConditions(
												!checkedTermsConditions
											);
										}}
									/>
									<p className="text-sm text-[#757575]">
										[HIPPA TERMS AND CONDITIONS ]
									</p>
								</div>
								<p className=" text-sm ">
									By confirming, you accept your{" "}
									<span className="underline underline-offset-1">
										Terms
									</span>{" "}
									and{" "}
									<span className="underline underline-offset-1">
										Policies
									</span>
								</p>
							</div>
							<Button
								className="bg-primary"
								type="submit"
								disabled={
									isCompleteReservationPending || !isValid
								}
							>
								{isCompleteReservationPending ? (
									<Loader size={18} />
								) : (
									"Schedule Appointment"
								)}
							</Button>
						</>
					)}
				</form>
			</div>
			<RequestIsLoading
				isLoading={isTokenInformationLoading}
				isWhite
				isFullpage
				size={24}
			/>
			<CancelTimeslotReservationModal
				isOpen={showCancelReservationModal}
				setIsOpen={setShowCancelReservationModal}
			/>
		</>
	);
};

export default IntakeForm;
