import { useEffect } from "react";
import useCustomToast from "../components/CustomToast";

interface ErrorNotificationProps {
	isError: boolean;
	message: string;
	type?: "error" | "success" | "loading" | undefined;
	id: string;
	duration: number;
	action?: () => void;
}

export const useErrorNotification = ({
	isError,
	message,
	type = "error",
	duration = 3000,
	action,
}: ErrorNotificationProps) => {
	const toast = useCustomToast();

	useEffect(() => {
		if (isError) {
			toast(message, {
				id: message,
				type: type,
				duration: duration,
			});
			action && action();
		}
	}, [duration, isError, message, toast, type]);
};
