import {
	JoinWaitlistType,
	UpdateWaitlistActionType,
} from "@/src/types/waitlist";
import { AxiosResponse } from "axios";
import { FieldValues } from "react-hook-form";
import $http from "../xhr";

export const APIVersion1ContactUs = async (
	data: FieldValues
): Promise<AxiosResponse<Record<string, string>>> =>
	$http.post("/admin/customer-contacts", data).then((res) => res.data);

export const APIVersion2GetWaitlistInfo = async (data: {
	waitlist_code?: string | null;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/status/${data.waitlist_code}`)
		.then((res) => res.data);

export const APIVersion2GetJoinInfo = async (data: {
	join_code?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/waitlist/join/${data.join_code}/info`)
		.then((res) => res.data);

export const APIVersion1JoinWaitlist = async (
	data: JoinWaitlistType & { join_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/join/${data.join_code}`, {
			...data,
			join_code: undefined,
		})
		.then((res) => res.data);

export const APIVersion1UpdateWaitlist = async (
	data: UpdateWaitlistActionType & { waitlist_code?: string }
): Promise<any> =>
	$http
		.post(`v2/customer/waitlist/update/${data.waitlist_code}`, {
			...data,
			waitlist_code: undefined,
		})
		.then((res) => res.data);

/** == SCHEDULING ENDPOINTS == */

export const APIVersion2GetScheduleInfo = async (data: {
	scheduling_code?: string;
	station_id?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/schedule/${data.scheduling_code}/info`)
		.then((res) => res.data);

export const APIVersion2GetScheduleStationInfo = async (data: {
	scheduling_code?: string;
	station_id?: string;
}): Promise<any> =>
	$http
		.get(
			`v2/customer/schedule/${data.scheduling_code}/info ${data.station_id ? "?" + data.station_id : ""}`
		)
		.then((res) => res.data);

export const APIVersion2GetScheduleLocationInfo = async (data: {
	scheduling_code?: string;
	location_id?: string;
}): Promise<any> =>
	$http
		.get(
			`v2/customer/schedule/${data.scheduling_code}/info?${data.location_id}`
		)
		.then((res) => res.data);

export const APIVersion2GetScheduleStatus = async (data: {
	scheduling_code?: string;
}): Promise<any> =>
	$http
		.get(`v2/customer/schedule/status/${data.scheduling_code}`)
		.then((res) => res.data);

//Note: the below endpoint may be redundant
export const APIVersion2GetStationAvailableTimeSlotsForSchedule = async (
	stationId: number | undefined,
	data: {
		appointment_type_id?: number;
		date: string | undefined;
	}
): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.appointment_type_id && {
			appointment_type_id: data.appointment_type_id.toString(),
		}),
		date: data.date || "", // If date is undefined, use an empty string
	}).toString();

	return $http
		.get(
			`/v2/customer/schedule/available-blocks-for-scheduling/${stationId}?${queryParams}`
		)
		.then((res) => res.data);
};

export const APIVersion2GetServiceDetail = async (
	serviceId: number | undefined,
	data: {
		location_id?: number;
		stationId?: number;
		appointment_type_id?: number;
		date: string | undefined;
	}
): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.location_id && {
			location_id: data.location_id.toString(),
		}),
		...(data.appointment_type_id && {
			appointment_type_id: data.appointment_type_id.toString(),
		}),
		...(data.stationId && {
			station_id: data.stationId.toString(),
		}),
		date: data.date || "", // If date is undefined, use an empty string
	}).toString();

	return $http
		.get(
			`/v2/customer/schedule/get-service-detail/${serviceId}?${queryParams}`
		)
		.then((res) => res.data);
};

export const APIVersion2GetStationBasedTimeslots = async (
	serviceId: number | undefined,
	data: {
		location_id: number | undefined;
		date?: string | undefined;
		start_time: string;
		scheduling_code: string;
	}
): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.location_id && {
			location_id: data.location_id.toString(),
		}),
		...(data.date && {
			date: data.date,
		}),
		start_time: data.start_time,
		scheduling_code: data.scheduling_code,
	}).toString();

	return $http
		.get(
			`/v2/customer/schedule/get-stations-for-service-and-start-time/${serviceId}?${queryParams}`
		)
		.then((res) => res.data);
};

export const APIVersion2GetServiceDetailForSchedule = async (data: {
	serviceId: number;
	locationId?: number;
	stationId?: number;
	appointment_type_id?: number;
	date: string | undefined;
}): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.locationId && {
			location_id: data.locationId.toString(),
		}),
		...(data.appointment_type_id && {
			appointment_type_id: data.appointment_type_id.toString(),
		}),
		...(data.stationId && {
			station_id: data.stationId.toString(),
		}),
		date: data.date || "", // If date is undefined, use an empty string
	}).toString();
	return $http
		.get(
			`/v2/customer/schedule/get-service-detail/${data.serviceId}/?${queryParams}`
		)
		.then((res) => res.data);
};
export const APIVersion2GetServiceAvailableTimeSlotsForSchedule = async (data: {
	serviceId?: number;
	locationId?: number;
	stationId?: number;
	appointment_type_id?: number;
	date: string | undefined;
}): Promise<any> => {
	const queryParams = new URLSearchParams({
		...(data.locationId && {
			location_id: data.locationId.toString(),
		}),
		...(data.appointment_type_id && {
			appointment_type_id: data.appointment_type_id.toString(),
		}),
		...(data.serviceId && {
			service_id: data.serviceId.toString(),
		}),
		...(data.stationId && {
			station_id: data.stationId.toString(),
		}),
		date: data.date || "", // If date is undefined, use an empty string
	}).toString();
	return $http
		.get(
			`/v2/customer/schedule/available-blocks-for-scheduling?${queryParams}`
		)
		.then((res) => res.data);
};

export const APIVersion2GetScheduleCodeCustomFields = async (
	scheduling_code: string
): Promise<any> => {
	return $http
		.get(`/v2/customer/schedule/station-custom-fields/${scheduling_code}`)
		.then((res) => res.data);
};

export const APIVersion2GetSlotTokenInformation = async (
	slot_token: string
): Promise<any> => {
	return $http
		.get(`/v2/customer/schedule/slot/${slot_token}/info`)
		.then((res) => res.data);
};

export const APIVersion2CreateAppointment = async (
	scheduling_code: string,
	data
): Promise<any> =>
	$http
		.post(`/v2/customer/schedule/${scheduling_code}/schedule`, data)
		.then((res) => res.data);

export const APIVersion2UpdateAppointment = async (
	appointmentId: string,
	data: {
		action:
			| "done"
			| "cancel"
			| "reschedule"
			| "update_custom_intakes"
			| "check_in"
			| "undo_checkin";
		reschedule_date?: string;
		reschedule_time?: string;
		custom_intakes?: Record<string, any>;
	}
): Promise<any> =>
	$http
		.post("/v2/customer/schedule/update/" + appointmentId, data)
		.then((res) => res.data);

export const APIVersion2VerifyCustomer = async (
	schedule_code: string,
	data
): Promise<any> =>
	$http
		.post(`/v2/customer/schedule/${schedule_code}/verify-customer`, data)
		.then((res) => res.data);

export const APIVersion2IncreaseReservationTime = async (
	slot_token: string
): Promise<any> => {
	return $http
		.post(`/v2/customer/schedule/slot/${slot_token}/increase-expire`)
		.then((res) => res.data);
};
export const APIVersion2CompleteReservation = async (
	slot_token: string,
	data
): Promise<any> => {
	return $http
		.post(`/v2/customer/schedule/slot/${slot_token}/complete-booking`, data)
		.then((res) => res.data);
};

export const APIVersion2ReserveTimeslot = async (
	schedule_code: string,
	data: {
		schedule_code: string;
		customer_verification_token?: string;
		is_on_notification_waitlist?: boolean;
		service_id?: number;
		appointment_type_id?: number;
		date: string;
		start_time: string;
	}
): Promise<any> =>
	$http
		.post(`/v2/customer/schedule/${schedule_code}/book-slot`, data)
		.then((res) => res.data);

export const APIVersion2CancelReservedTimeslot = async (
	slot_token: string
): Promise<any> => {
	return $http
		.delete(`/v2/customer/schedule/slot/${slot_token}/cancel`)
		.then((res) => res.data);
};
