import useScheduleStore from "@/src/store/useScheduleStore";
import { cn } from "@/src/utils/general";
import { ReactNode } from "react";

interface FormLayoutProps {
	children: ReactNode;
}

const FormLayout = ({ children }: FormLayoutProps) => {
	const { step, setStep } = useScheduleStore();
	return (
		<div className="flex flex-col gap-y-4">
			<div className=" flex w-full justify-between bg-white font-hoves">
				<div className="flex flex-1 items-center border-b-2 border-[#053969] py-5 lg:pl-12">
					<button onClick={() => setStep(0)}>
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M13.9998 6L8.70696 11.2929C8.31643 11.6834 8.31643 12.3166 8.70696 12.7071L13.9998 18"
								stroke="#858C95"
								strokeWidth="2"
								strokeLinecap="round"
							/>
						</svg>
					</button>

					<button
						className="flex w-full justify-center text-center text-[15px] font-semibold text-primary md:text-lg"
						onClick={() => setStep(0)}
					>
						Time & Date
					</button>
				</div>
				<div
					className={cn(
						"flex flex-1 items-center border-b-2 border-[#053969] py-5 pr-6 lg:pr-12"
					)}
				>
					<button
						className={cn(
							"flex w-full justify-center text-center text-[15px] text-main-4 md:text-lg",
							{
								"font-semibold text-primary": step === 1,
							}
						)}
						onClick={() => setStep(1)}
						disabled={step == 1}
					>
						Information
					</button>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M18 6L6 18M18 18L6 6.00001"
							stroke="#858C95"
							strokeWidth="2"
							strokeLinecap="round"
						/>
					</svg>
				</div>
			</div>
			<div className="h-full">{children}</div>
		</div>
	);
};

export default FormLayout;
