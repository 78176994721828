import { useState, useEffect } from "react";

export const useDebouncedSearch = (input, delay = 300) => {
	const [debouncedValue, setDebouncedValue] = useState(input);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(input);
		}, delay);

		return () => clearTimeout(handler);
	}, [input, delay]);

	return debouncedValue;
};
