import { useCallback, useEffect, useRef, useState } from "react";
import AdditionalTimeModal from "./AdditionalTimeModal";
import { useIncreaseReservedTime } from "@/src/store/slices/scheduleSlice";
import useScheduleStore from "@/src/store/useScheduleStore";
import useCustomToast from "@/src/components/CustomToast";

const CountdownTimer: React.FC = () => {
	const [timeLeft, setTimeLeft] = useState<number>(600);
	const [canAddTime, setCanAddTime] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const toast = useCustomToast();
	const minutesToAddRef = useRef<number>(0);
	const slotToken = useScheduleStore((s) => s.temporarySlotToken);

	const formatTime = (seconds: number): string => {
		const mins = Math.floor(seconds / 60);
		const secs = seconds % 60;
		return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
	};

	const startTimer = useCallback(() => {
		intervalRef.current = setInterval(() => {
			setTimeLeft((prev) => {
				if (prev <= 180 && canAddTime) {
					setIsModalOpen(true);
				}
				if (prev <= 160 && canAddTime) {
					setIsModalOpen(false);
				}
				if (prev <= 0) {
					if (intervalRef.current) clearInterval(intervalRef.current);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
	}, [canAddTime]);

	const addTime = useCallback(
		(minutes: number) => {
			if (canAddTime) {
				minutesToAddRef.current = minutes;
				mutateIncreaseReservedTime({ slot_token: slotToken as string });
			}
		},
		[canAddTime]
	);

	useEffect(() => {
		startTimer();
		return () => {
			if (intervalRef.current) clearInterval(intervalRef.current);
		};
	}, [startTimer]);

	const { mutate: mutateIncreaseReservedTime } = useIncreaseReservedTime({
		onSuccess: (response) => {
			const message = (response as unknown as { message: string })
				?.message;
			setTimeLeft((prev) => prev + minutesToAddRef.current * 60);
			setCanAddTime(false);
			setIsModalOpen(false);
			toast(message, {
				id: "increase-reservation-time",
				type: "success",
				duration: 2000,
			});
		},
	});

	return (
		<div className=" max-w-xs rounded-lg bg-white px-4 py-2">
			<div className=" text-center text-xl font-bold">
				Time Left: {formatTime(timeLeft)}
			</div>
			{/* {canAddTime && (
				<div className="flex justify-center space-x-2">
					<button
						onClick={() => addTime(5)}
						className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
					>
						+5 Minutes
					</button>
					<button
						onClick={() => addTime(10)}
						className="rounded bg-green-500 px-4 py-2 text-white hover:bg-green-600"
					>
						+10 Minutes
					</button>
				</div>
			)} */}
			<AdditionalTimeModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onAddTime={addTime}
			/>
		</div>
	);
};

export default CountdownTimer;
