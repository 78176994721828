import { useDebouncedSearch } from "@/src/hooks/useDebouncedSearch";
import ProviderSelectionCard from "./ProviderSelectionCard";
import { Station } from "@/src/types/schedule";
import { useState } from "react";
import { IoSearch } from "react-icons/io5";

interface ProviderSelectionProps {
	stations?: Station[];
	setSelectedProvider: (provider: Record<string, any>) => void;
}
const ProviderSelection = ({
	stations,
	setSelectedProvider,
}: ProviderSelectionProps) => {
	const [searchTerm, setSearchTerm] = useState("");
	const debouncedSearchTerm = useDebouncedSearch(searchTerm, 300);

	const filteredProviders = stations?.filter((provider) =>
		provider.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
	);

	const handleProviderSelection = (provider) => {
		setSelectedProvider(provider);
	};
	return (
		<div className="flex flex-col gap-y-4 pt-2">
			<div className="relative ">
				<span className="absolute left-2 top-2">
					<IoSearch className="text-sm text-[#858C95]" />
				</span>
				<input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					className="w-full rounded-md border border-[#E5E5E7] py-1 pl-6 text-sm text-[#858C95]"
					placeholder="Quick search by name"
				/>
			</div>
			<div className="flex flex-col gap-y-2">
				<ul className="flex flex-col gap-y-2">
					{stations?.length ? (
						filteredProviders?.map((provider) => (
							<ProviderSelectionCard
								key={provider?.id}
								station={provider}
								onClick={() =>
									handleProviderSelection(provider)
								}
							/>
						))
					) : (
						<p className="mt-2 text-center text-sm ">
							No Providers Available{" "}
						</p>
					)}
				</ul>
			</div>
		</div>
	);
};

export default ProviderSelection;
