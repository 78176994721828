import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from "@/src/components/ui/dialog";
import React from "react";
import { Button } from "@/src/components/ui/button";
import moment from "moment";
import Loader from "@/src/components/Loader/Loader";
import { formatTimeFrom24HourToMeridian } from "@/src/utils/date";
import { DialogDescription } from "@radix-ui/react-dialog";

interface ConfirmAppointmentModalProps {
	isOpen: boolean;
	handleReserveTimeslot: (e: React.FormEvent) => void;
	data: { appointment_time: string; appointment_date: string };
	handleCloseModal: () => void;
	isLoading: boolean;
}
const ConfirmAppointmentModal = ({
	isOpen,
	handleReserveTimeslot,
	data,
	handleCloseModal,
	isLoading,
}: ConfirmAppointmentModalProps) => {
	// const checkedIn = data?.data?.data?.check_in_status == null ? false : true;

	return (
		<Dialog open={isOpen}>
			{/* <DialogTrigger
				className="h-10 w-full rounded-md bg-primary px-4 py-2 text-primary-foreground text-white hover:bg-primary/90"
				onClick={() => setIsOpen(true)}
			>
				<span>{checkedIn ? "Undo Check-in " : "Check-in"}</span>
			</DialogTrigger> */}

			<DialogContent className="w-full max-w-[360px] gap-8 rounded-[10px] p-6">
				<div className=" flex flex-col gap-y-6">
					<div className="flex flex-col space-y-2">
						<DialogHeader>
							<DialogDescription className="sr-only">
								reserve a timeslot and fill your intake fields
							</DialogDescription>
							<DialogTitle className="flex items-center justify-between">
								<h1 className="text-[22px] font-semibold leading-[30px] tracking-[-0.22px]">
									Confirm Appointment
								</h1>
								<DialogClose onClick={handleCloseModal}>
									<i className="mgc_close_line before:!text-main-4" />
								</DialogClose>
							</DialogTitle>
						</DialogHeader>
						<p className="flex items-center gap-2 font-semibold text-[#005893]">
							<i className="mgc_schedule_line before:!text-primary" />
							{data?.appointment_date
								? moment(data?.appointment_date)?.format(
										"Do MMMM YYYY"
									)
								: "N/A"}
						</p>
						<p className="flex items-center gap-2 font-semibold leading-loose text-[#005893]">
							<i className="mgc_alarm_1_line before:!text-primary" />
							{data?.appointment_time
								? formatTimeFrom24HourToMeridian(
										data?.appointment_time?.slice(0, -3)
									)
								: "N/A"}
						</p>

						<p className="text-base tracking-[-0.16px] text-main-1">
							Once you reserve this time slot, you will have{" "}
							<b>10 minutes</b> to complete the required form and
							input your details.
						</p>
					</div>

					<DialogFooter className="flex w-full !flex-row space-x-2.5">
						<Button
							className="flex-1"
							variant="ghost"
							onClick={handleCloseModal}
						>
							Cancel
						</Button>
						<Button
							className="flex-1 text-white"
							type="button"
							onClick={handleReserveTimeslot}
							disabled={isLoading}
						>
							{isLoading ? (
								<Loader size={18} />
							) : (
								"Proceed with appointment"
							)}
						</Button>
					</DialogFooter>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default ConfirmAppointmentModal;
