import React, { useEffect, useState } from "react";
import FormLayout from "./components/FormLayout";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import TimeSlot from "./components/TimeSlot";
import { Calendar } from "@/src/components/ui/calendar";
import {
	useGetServiceAvailableTimeSlots,
	useReserveTimeslot,
} from "@/src/store/slices/scheduleSlice";
import { useFormContext } from "./helpers/ScheduleFormContext";
import { cn } from "@/src/utils/general";
import Loader from "@/src/components/Loader/Loader";
import moment from "moment";
import CustomSelect from "@/src/components/common/CustomSelect";
import { useParams } from "react-router";
import ConfirmAppointmentModal from "./components/ConfirmAppointmentModal";

const BookProviderTime = () => {
	const { scheduling_code } = useParams();
	const {
		setStep,
		appointmentDetails,
		bookingType,
		selectedStation,
		validationCode,
		validationDetails,
		setScheduleCode,
		setTemporarySlotToken,
	} = useScheduleStore();
	const { formData, setFormData } = useFormContext();
	const [selectedDate, setSelectedDate] = useState<Date | undefined>(
		formData?.appointmentDetails?.date
			? new Date(formData?.appointmentDetails?.date)
			: new Date()
	);
	const [selectedService, setSelectedService] = useState<string | null>(null);
	const [selectedTime, setSelectedTime] = useState<string | null>(null);
	// const [selectedAppointmentType, setSelectedAppointmentType] =
	// 	useState<any>(null);
	const [waitlistChecked, setWaitlistChecked] = useState<boolean>(false);
	const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
		useState(false);
	const formattedDate = selectedDate
		? moment(selectedDate).startOf("day").format("YYYY-MM-DD")
		: undefined;

	const booking_code: string =
		bookingType == "station"
			? scheduling_code || selectedStation?.schedule_code
			: selectedStation?.schedule_code;

	const location_id =
		bookingType == "location"
			? selectedStation?.location_id
			: bookingType == "station"
				? appointmentDetails?.location?.id
				: appointmentDetails?.id;

	const {
		data: timeslotsData,
		isLoading: isTimeSlotsLoading,
		error,
	} = useGetServiceAvailableTimeSlots({
		// appointment_type_id: selectedAppointmentType?.value,
		date: formattedDate,
		locationId: location_id,
		serviceId: selectedService ? parseInt(selectedService) : undefined,
		stationId:
			bookingType == "station"
				? // ? appointmentDetails?.station?.id
					appointmentDetails?.id
				: selectedStation?.id,
	});

	const timeslots = timeslotsData?.data || appointmentDetails?.time_slots;

	useEffect(() => {
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	}, []);
	useEffect(() => {
		setSelectedTime(null);
	}, [formattedDate]);

	// const handleAppointmentTypeChange = (selectedOption: any) => {
	// 	setSelectedAppointmentType(selectedOption);
	// 	setFormData((prevData) => ({
	// 		...prevData,
	// 		appointmentDetails: {
	// 			...prevData.appointmentDetails,
	// 			appointmentTypeId: selectedOption?.value,
	// 		},
	// 	}));
	// };
	const handleServiceTypeChange = (selectedOption: any) => {
		setSelectedService(selectedOption);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				serviceType: selectedOption?.value,
			},
		}));
	};
	const handleDateChange = (date: Date | undefined) => {
		if (!date) return;

		const formattedDate = moment(date).startOf("day").format("YYYY-MM-DD");
		setSelectedDate(date);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				date: formattedDate,
			},
		}));
	};

	const handleTimeSlotChange = (time: string) => {
		setSelectedTime(time);
		setFormData((prevData) => ({
			...prevData,
			appointmentDetails: {
				...prevData.appointmentDetails,
				timeslot: time,
			},
		}));
	};

	const handleCloseConfirmationModal = () => {
		setIsConfirmationModalOpen(false);
	};

	const handleBookAppointment = (e: React.FormEvent) => {
		e.preventDefault();
		const data = {
			schedule_code: booking_code,
			is_on_notification_waitlist: waitlistChecked,
			service_id: formData.appointmentDetails.serviceType,
			// add appointment_type_id?: number;
			date: formattedDate ? formattedDate : "",
			start_time: formData.appointmentDetails.timeslot.slice(0, -3),
			...(validationDetails?.length && {
				customer_verification_token: validationCode,
			}),
		};

		reserveTimeslot({
			scheduling_code: booking_code,
			data,
		});
	};

	const {
		mutate: reserveTimeslot,
		isPending: isReserveTimeslotPending,
		isSuccess,
		data,
	} = useReserveTimeslot();

	useEffect(() => {
		if (isSuccess && !isReserveTimeslotPending) {
			setScheduleCode(data?.data?.schedule_code || booking_code);
			setTemporarySlotToken(data?.data?.token || undefined);
			setStep(3);
		}
	}, [
		isSuccess,
		setStep,
		isReserveTimeslotPending,
		data,
		setScheduleCode,
		booking_code,
		setTemporarySlotToken,
	]);

	return (
		<FormLayout>
			<form
				onSubmit={handleBookAppointment}
				className="bg-primary-3 flex flex-col gap-y-4 px-6 pb-12 pt-6 font-hoves md:h-[100svh] md:overflow-auto lg:h-auto lg:px-12 2xl:h-screen"
			>
				<div className="h-full w-full items-center justify-center gap-6 align-top md:flex md:items-start">
					<div className="flex flex-col gap-y-5">
						<div className="border-primary-3 flex flex-col gap-y-2 rounded-[12px] bg-white px-6 py-4">
							<div className="flex items-center gap-x-2 ">
								<i className="mgc_building_1_line before:!text-primary" />
								<p className="text-sm font-semibold text-main-1">
									{bookingType == "station"
										? appointmentDetails?.location?.name
										: appointmentDetails?.name}
								</p>
							</div>
							<div className="flex items-center gap-x-2 ">
								<i className="mgc_location_line before:!text-primary" />
								<p className="text-sm text-[#6D748D]">
									{bookingType == "station"
										? appointmentDetails?.location?.address
										: appointmentDetails?.address}
								</p>
							</div>
							<div className="flex items-center gap-x-2">
								<i className="mgc_store_line before:!text-[#043B6D]" />
								<p className="text-sm text-main-1">
									{bookingType == "station"
										? appointmentDetails?.name
										: selectedStation?.name}
								</p>
							</div>
						</div>
						<div className=" border-primary-3  flex flex-col gap-y-2 rounded-[12px] bg-white px-4 py-6">
							<p className="font-medium text-main-1">
								Select Service
							</p>
							<CustomSelect
								className="w-full"
								placeholder="Select service"
								isDisabled={
									!appointmentDetails?.services?.length
								}
								options={appointmentDetails?.services?.map(
									(service) => ({
										value: service.id,
										label: service.name,
									})
								)}
								value={selectedService}
								onChange={handleServiceTypeChange}
							/>
						</div>
						{/* <div className=" border-primary-3  flex flex-col gap-y-1 rounded-[12px] bg-white px-4 py-6">
							<p className="font-medium text-main-1">
								Select Appointment Type
							</p>
							<CustomSelect
								className="w-full"
								placeholder="Appointment Type"
								options={appointmentDetails?.appointment_types?.map(
									(type) => ({
										value: type.id,
										label: type.title,
									})
								)}
								isDisabled={
									!appointmentDetails?.appointment_types
										?.length
								}
								value={selectedAppointmentType}
								onChange={handleAppointmentTypeChange}
							/>
						</div> */}
						<div className="w-full justify-center rounded-md border bg-white px-4 py-2">
							<h5 className="font-medium text-main-1 ">
								Select Your Appointment Date
							</h5>
							<div className="flex justify-center">
								<Calendar
									mode="single"
									className="text-[20px]"
									styles={{
										head_cell: {
											width: "50px",
										},
										cell: {
											width: "50px",
										},
										table: {
											maxWidth: "none",
										},
										day: {
											margin: "auto",
										},
									}}
									selected={selectedDate}
									onSelect={handleDateChange}
									disabled={{ before: new Date() }}
								/>
							</div>
						</div>
					</div>

					<div className="flex w-full flex-col gap-y-3 pt-4 md:pt-0">
						<div className=" h-auto w-full rounded-[12px] bg-white px-6 py-6 font-hoves">
							<p className="text-base font-medium leading-[30px] -tracking-1% text-main-1 md:text-[22px]">
								Select your Appointment Time
							</p>
							<>
								{isTimeSlotsLoading && (
									<div className="mx-auto my-3 flex h-full w-full items-center justify-center self-center">
										<Loader size={25} />
									</div>
								)}

								{error && (
									<p className="w-full py-4 text-center">
										Error loading time slots
									</p>
								)}

								{!isTimeSlotsLoading &&
								!error &&
								timeslots?.length > 1 ? (
									<div className="mt-4 flex w-full flex-wrap justify-center gap-x-2 gap-y-4">
										{timeslots?.map(
											(timeslot: any, i: number) => (
												<TimeSlot
													selected={
														selectedTime ===
															timeslot.start_time ||
														formData
															?.appointmentDetails
															?.timeslot ===
															timeslot.start_time
													}
													onClick={() =>
														handleTimeSlotChange(
															timeslot.start_time
														)
													}
													className="w-[144px]"
													timeslot={timeslot}
													key={i}
												/>
											)
										)}
									</div>
								) : (
									<p
										className={cn("py-4", {
											hidden: isTimeSlotsLoading || error,
										})}
									>
										No timeslots available for this date.
									</p>
								)}
							</>
						</div>
						<div className="flex flex-col gap-y-2 pt-4">
							<p className="text-sm font-light">
								Would you like to be added to the waitlist?
							</p>
							<div className="flex items-center gap-2 font-light ">
								<input
									type="checkbox"
									className="accent-primary"
									checked={waitlistChecked}
									onChange={() => {
										setWaitlistChecked(!waitlistChecked);
										setFormData((prevData) => ({
											...prevData,
											appointmentDetails: {
												...prevData.appointmentDetails,
												waitlist_checked:
													!waitlistChecked,
											},
										}));
									}}
								/>
								<p className="text-sm text-[#757575]">
									Yes, if an appointment opens up before my
									selected time, please notify me.
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="">
					<Button
						className="mt-6 w-full bg-[#043B6D] px-6 py-1.5"
						type="button"
						disabled={
							!formData?.appointmentDetails?.date ||
							!formData?.appointmentDetails?.timeslot
						}
						onClick={() =>
							setIsConfirmationModalOpen(!isConfirmationModalOpen)
						}
					>
						{isReserveTimeslotPending ? (
							<Loader size={14} />
						) : (
							"Schedule Appointment"
						)}
					</Button>
				</div>
			</form>
			<ConfirmAppointmentModal
				isOpen={isConfirmationModalOpen}
				handleCloseModal={handleCloseConfirmationModal}
				handleReserveTimeslot={handleBookAppointment}
				data={{
					appointment_date: formData?.appointmentDetails?.date,
					appointment_time: formData?.appointmentDetails?.timeslot,
				}}
				isLoading={isReserveTimeslotPending}
			/>
		</FormLayout>
	);
};

export default BookProviderTime;
