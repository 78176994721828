import useCustomToast from "@/src/components/CustomToast";
import Loader from "@/src/components/Loader/Loader";
import RequestIsLoading from "@/src/components/Loader/RequestIsLoading";
import { Button } from "@/src/components/ui/button";
import CustomIntakeField from "@/src/components/Waitlist/CustomIntakeField";
import {
	useGetScheduleCodeCustomFields,
	useUpdateAppointment,
} from "@/src/store/slices/scheduleSlice";
import useScheduleStore from "@/src/store/useScheduleStore";
import { createScheduleCustomFieldsSchema } from "@/src/types/schedule";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router";
import { z } from "zod";

const StatusIntakeForm = () => {
	//would get details from status and identify if custom fields have been filled then show,
	// would only show if it hasnt been filled or redirects to status page

	//get details from store first and would go with appointment submission
	const queryClient = useQueryClient();
	const { appointment_code } = useParams();
	const [customFieldsSchema, setCustomFieldsSchema] = useState(
		createScheduleCustomFieldsSchema([])
	);

	const toast = useCustomToast();
	const { mutate: updateAppointment, isPending } = useUpdateAppointment();
	const navigate = useNavigate();
	const { appointmentStatusDetails } = useScheduleStore();

	const {
		data: customFieldData,
		isSuccess,
		isLoading,
	} = useGetScheduleCodeCustomFields(appointment_code!);

	useEffect(() => {
		if (customFieldData === null) {
			return;
		}
		if (isSuccess && customFieldData?.data) {
			const newSchema = createScheduleCustomFieldsSchema(
				customFieldData?.data
			);
			setCustomFieldsSchema(newSchema);
		} else {
			navigate(`/schedule/${appointment_code}/status`);
		}
	}, [appointment_code, navigate, isSuccess, customFieldData]);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<z.infer<typeof customFieldsSchema>>({
		resolver: zodResolver(customFieldsSchema),
	});

	const customIntakesFormat = (data: z.infer<typeof customFieldsSchema>) => {
		if (!data?.custom_intakes) return undefined;
		const fullCustomIntakes: Record<string, any> = {};
		customFieldData?.data?.forEach((intake) => {
			if (!data?.custom_intakes) return undefined;
			const value = data?.custom_intakes[intake.key];

			switch (intake.type) {
				case "text":
					fullCustomIntakes[intake.key] = value;
					break;
				case "numeric":
					fullCustomIntakes[intake.key] = value;
					break;

				case "boolean":
					fullCustomIntakes[intake.key] = value;
					break;

				case "date":
					fullCustomIntakes[intake.key] =
						value && value instanceof Date
							? value.toISOString().split("T")[0]
							: value;
					break;

				case "date_range":
					if (
						value &&
						typeof value === "object" &&
						"from" in value &&
						"to" in value
					) {
						fullCustomIntakes[intake.key] = `${
							value.from instanceof Date
								? value.from.toISOString().split("T")[0]
								: value.from
						} - ${
							value.to instanceof Date
								? value.to.toISOString().split("T")[0]
								: value.to
						}`;
					}
					break;

				case "dropdown":
					fullCustomIntakes[intake.key] = Array.isArray(value)
						? value.map((v) => v.value)
						: undefined;
					break;
				case "checkbox":
					fullCustomIntakes[intake.key] = value;
					break;

				case "attachment":
					// Handle file attachment (you might need to implement file upload logic)
					fullCustomIntakes[intake.key] =
						value instanceof File ? value.name : value;
					break;

				default:
					fullCustomIntakes[intake.key] = value;
			}
		});
		// console.log(fullCustomIntakes);
		return fullCustomIntakes;
	};

	const onSubmit: SubmitHandler<z.infer<typeof customFieldsSchema>> = (
		data
	) => {
		if (!appointment_code) {
			toast("Appointment code is missing.", {
				type: "error",
				id: "missing-appointment-code",
			});
			// todo: redirect to default path or something
			return;
		}
		const formattedCustomIntakes = customIntakesFormat(data);
		updateAppointment(
			{
				appointmentId: appointment_code!,
				data: {
					action: "update_custom_intakes",
					custom_intakes: formattedCustomIntakes,
				},
			},
			{
				onSuccess: () => {
					toast("Intake form submitted successfully.", {
						type: "success",
						id: "updated-appointment",
					});
					queryClient.invalidateQueries({
						queryKey: ["scheduleStatus"],
					});
					navigate(`/schedule/${appointment_code}/status`);
				},
				onError: (error: any) => {
					const errorMessage =
						error?.response?.data?.message ||
						"Failed to submit intake form, please try again.";
					toast(errorMessage, {
						type: "error",
						id: "updated-appointment",
					});
				},
			}
		);
	};

	return (
		<>
			<div className="flex min-h-[100svh] flex-col bg-[#E5E5E7]">
				<div className="flex w-full items-center border-b-2 border-b-primary bg-white font-hoves ">
					<h1 className="mx-auto w-full py-4 text-center font-semibold text-primary ">
						Intake Form
					</h1>
					<button
						className="ml-auto w-fit pr-4"
						onClick={() => navigate(-1)}
					>
						<IoClose className="text-lg" />
					</button>
				</div>
				<form
					onSubmit={handleSubmit(onSubmit)}
					className="mx-auto my-4 flex w-full max-w-[95%] flex-col gap-y-3 rounded-md bg-white p-4 md:max-w-[80%]"
				>
					<div className="flex flex-col gap-y-2">
						<h1 className="font-semibold text-main-1">
							Now, For Some More Details
						</h1>
						<p className="max-w-[294px] text-[#6D748D]">
							Only your first name will be shown on the waitlist
							community
						</p>
					</div>
					<div className="flex flex-col gap-y-4">
						{appointmentStatusDetails?.data?.station?.custom_fields.map(
							(custom, idx) => (
								<CustomIntakeField
									key={idx}
									intake={custom}
									register={register}
									errors={errors}
									control={control}
								/>
							)
						)}
					</div>
					<p className="pt-4 text-sm ">
						By confirming, you accept your{" "}
						<span className="underline underline-offset-1">
							Terms
						</span>{" "}
						and{" "}
						<span className="underline underline-offset-1">
							Policies
						</span>
					</p>
					<Button
						className="bg-primary"
						type="submit"
						disabled={isPending}
					>
						{isPending ? <Loader size={18} /> : "Save"}
					</Button>
				</form>
			</div>
			<RequestIsLoading
				isLoading={isLoading}
				isWhite
				isFullpage
				size={24}
			/>
		</>
	);
};

export default StatusIntakeForm;
