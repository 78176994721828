import welcomeImage from "@/public/assets/calendar_icon.svg";
import { Button } from "@/src/components/ui/button";
import useScheduleStore from "@/src/store/useScheduleStore";
import { formatTimeFrom24HourToMeridian } from "@/src/utils/date";
import { useMemo, useState } from "react";
import CancelAppointmentModal from "./components/CancelAppointmentModal";
import { useNavigate } from "react-router";
import { ScheduleStatus } from "@/src/utils/constants";
import { cn } from "@/src/utils/general";

const AppointmentConfirmation = () => {
	const navigate = useNavigate();
	const { submissionDetails } = useScheduleStore();
	const formattedDate = useMemo(() => {
		const date = submissionDetails?.appointment_date?.split("T")[0];
		const time = formatTimeFrom24HourToMeridian(
			submissionDetails?.appointment_date?.split("T")[1]?.split(".")[0] ??
				"N/A"
		);
		return { date, time };
	}, [submissionDetails?.appointment_date]);
	const [isCancelAppointmentOpen, setIsCancelAppointmentOpen] =
		useState(false);
	const appointment_code = submissionDetails?.track_code;
	const status = submissionDetails?.status;
	const showStatus =
		ScheduleStatus[status] == "pending" ||
		ScheduleStatus[status] == "confirmed";

	const checkEmail = (email: string) => {
		const emailDomain = email.split("@")[1].split(".")[0];
		switch (emailDomain) {
			case "gmail":
				return window.open("https://mail.google.com");
			case "outlook":
				return window.open("https://outlook.office.com/mail");
			case "yahoo":
				return window.open("https://mail.yahoo.com");
			default:
				return;
		}
	};

	return (
		<>
			<div className="  flex min-h-[100svh] min-w-full flex-col items-center gap-y-4 bg-[#F3F3F3] px-4 md:flex-row md:px-20">
				<div className="my-4 flex w-full justify-center ">
					<img src={welcomeImage} alt="" className="md:w-[60%]" />
				</div>

				<div className="flex w-full flex-col gap-y-3">
					{/* <div className="flex flex-col gap-y-8 rounded-[12px] bg-white px-6 py-4">
						<div className="flex flex-col gap-y-1">
							<h3 className="text-[28px] font-bold text-main-1">
								Appointment Requested!
							</h3>
							<p className="max-w-[294px] text-sm text-[#6D748D]">
								Appointment request sent successfully. You will
								receive an email when the appointment has been
								approved.
							</p>
							<p className="text-sm text-[#6D748D]">
								Meanwhile, please fill out the intake form to
								speed up the process.
							</p>
						</div>

						<Button
							className="bg-[#043B6D]"
							onClick={() =>
								navigate(
									`/schedule/${appointment_code}/intake-form`
								)
							}
						>
							Fill out Form
						</Button>
					</div> */}

					<div className="flex flex-col rounded-[12px] bg-white px-6 py-4">
						<div>
							<h1 className="text-[28px] font-bold text-main-1">
								Appointment Details
							</h1>
							{/* check the conditional here */}
							{!submissionDetails?.customer?.custom_intakes
								?.length && (
								<p>
									Check your{" "}
									<span
										onClick={() =>
											checkEmail(
												submissionDetails?.customer
													?.email
											)
										}
										className=" pb-1 text-primary underline underline-offset-1"
									>
										email
									</span>{" "}
									for confirmation
								</p>
							)}
						</div>

						<div className="pt-6">
							<ul className="flex flex-col gap-y-2">
								<li className="flex items-center gap-x-2">
									<i className="mgc_schedule_line before:!text-primary" />
									<p className="font-bold text-main-1">
										<span>{formattedDate.date}</span> at{" "}
										<span>{formattedDate.time}</span>
									</p>
								</li>
								<li className="flex items-center gap-x-2">
									<i className="mgc_building_1_line before:!text-primary" />
									<p className="text-main-1">
										{
											submissionDetails?.station?.business
												?.name
										}
									</p>
								</li>
								<li className="flex items-center gap-x-2">
									<i className="mgc_location_line before:!text-primary" />
									<p className="text-main-1">
										{submissionDetails?.station?.address}
									</p>
								</li>

								<li className="flex items-center gap-x-2">
									<i className="mgc_store_line before:!text-primary" />
									<p className="text-[#6D748D]">
										{submissionDetails?.station?.name}
									</p>
								</li>

								{showStatus && (
									<>
										<li className="flex items-center gap-x-2 px-6">
											<p className="text-main-1">
												Status:{" "}
												<span
													className={cn(
														"capitalize",
														{
															"text-[#259C36]":
																ScheduleStatus[
																	status
																] ==
																"confirmed",
															"text-[#F53A46]":
																ScheduleStatus[
																	status
																] == "pending",
														}
													)}
												>
													{ScheduleStatus[status]}
												</span>
											</p>
										</li>
									</>
								)}
							</ul>
						</div>
						<div className="flex flex-col gap-y-6 pt-16">
							{/* Also the one for add to google calendar  */}
							{/* rework the logic for fill out form to work when there are present intake fields */}
							{!submissionDetails?.customer?.custom_intakes
								?.length && (
								<Button
									className="bg-[#043B6D]"
									onClick={() =>
										navigate(
											`/schedule/${appointment_code}/intake-form`
										)
									}
								>
									Fill out Form
								</Button>
							)}

							<div className="flex w-full items-center justify-between gap-x-2 ">
								<Button
									variant={"outline-destructive"}
									className="w-full"
									onClick={() =>
										setIsCancelAppointmentOpen(true)
									}
								>
									Cancel Appointment
								</Button>
								{/* <Button
									variant={"default"}
									className="w-full bg-[#043B6D]"
									onClick={() =>
										navigate(
											`/schedule/${appointment_code}/reschedule`
										)
									}
								>
									Reschedule
								</Button> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			<CancelAppointmentModal
				data={submissionDetails}
				appointment_code={appointment_code}
				isOpen={isCancelAppointmentOpen}
				setIsOpen={setIsCancelAppointmentOpen}
			/>
		</>
	);
};

export default AppointmentConfirmation;
